.debug {
  border: 1px dashed red  
}

.scroll-component {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.ReactModal__Content--after-open {
  overflow-y: scroll !important;
  max-height: 100%;
}

.ReactModalPortal {
  z-index: 2;
  .ReactModal__Overlay {
    z-index: 2;
    
    .ReactModal__Content {
      background-color: #FFF;
      border: 2px solid #005643;
      border-radius: 4px;
      @media only screen and (max-width: 400px) {
        border: none;
      }
      //min-height: 700px;
      //height: 700px;
     /* &.party {
        min-height: 600px;
        height: 600px;
      } */
      margin: 40px auto;
      @media only screen and (max-width: 400px) {
      inset: 0px default;
      height: auto;
      margin: auto;
      }
      max-width: 500px;
     
      .close-icon {
        cursor: pointer;
        margin: 5px 5px 5px auto;
        font-size: 2rem;
        &:hover {
          color:#005643;
        }
      }
      .header {
        display: flex;
        flex-direction: row;
      }
      h1 {
        text-align: center;
        flex-grow: 2;
        margin-left: 16px;
        color:#005643;
      }
    }
  }
}
body {
  background-color: #e7e7e7;
}
.App {
  &:before {
    content: "";
    background-image: url('./assets/eucalyptus.png');
    background-repeat: repeat-y;
    background-position:top;
    position: absolute;
    background-size: inherit;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.20;
    //transform: rotate(30deg);
    z-index:-1;
    height: 240vh;
    @media only screen and (max-width: 400px) {
    height: 240vh;
    left: -700px;
    background-size: contain;
    }
  }  
  color: #005643;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex:1;
  flex-wrap: nowrap;
  
  .accommodation-title, .partner-title, .content-title {
    margin-bottom: 0.5rem;
    margin-top:0;
  }
  .accommodation-text {
    margin-bottom: 10px;
  }
  > div:not(.footer):not(.separator):not(.rsvp):not(.patrick) {
    padding-left: 10px;
    padding-right: 10px;
    margin-top:5px;
    
    &.accommodation-list {
      margin-top: -5px;
      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
      .separator {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  
  > h2 {
    text-decoration: underline;
    @media only screen and (max-width: 400px) {
      :not(.planning-title) {
      margin-bottom: 0;
      }
    }
  }
}

.App-logo {
  height: 40px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-family: fontHeader;
  border-bottom: 1px solid;
  border-bottom-color: #005643;
  // background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  // color: white;
  .name {
    font-size: 10vmin;
  }
  .nameLink {
    font-family: fontHeader2;
    font-size: 6vmin;
  }
  .langSwitcher {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    @media only screen and (max-width: 420px) {
      flex-direction: column;
      top:5px;
      right:5px;
    }
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    //color:#c2c2c2;
    
    > span {
      font-size: 20px;
      opacity: 0.7;

      @media only screen and (max-width: 400px) {
        font-size:16px;
      }
      
      @media only screen and (min-width: 400px) {
        padding: 0 2px;
      }
      &:hover {
        color: #00422f;
        cursor: pointer;
      }
      &.active {
        font-weight: 800;
        color: #00422f;//#2196F3;
        opacity:1;
        cursor: initial;
      }
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.party-text {
  font-size: 24px;
}

.planning-title {
  margin-top: 0;
  margin-bottom: 10px;
}

.ceremony-drink, .meal-party {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
  > div {
    flex-direction:column;
    justify-items: center;
  }
}

.content
{
  font-family: fontContent;
  flex-grow: 1;
  margin-bottom: auto;
  //&:not(.last) {
    //border-bottom: 1px solid black;
   /* .bg-separator {
      background-image: url('./assets/separator2.png');
      background-position: bottom;
      height: 10rem;
      background-repeat: no-repeat;
    }*/
  //}
  &.ceremony,&.drink {
    .separator {
      display: none;
    }
    margin-bottom: 20px;
  }
  &.ceremony, &.meal, &.drink, &.party {
    width:50%;
    
  }
  @media only screen and (min-width: 400px) {
    &.ceremony, &.meal, &.drink, &.party {
    display: flex;
    width:50%;
    }
    &.ceremony, &.meal {
      align-items: end;
      margin-right: 20px;
    }
    &.drink, &.party {
      align-items: start;
      margin-left: 20px;
    }
    &.meal{ margin-right:35px;}
    &.party{ margin-left:35px;}
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .submit
      {
        display:inline-block;
        margin-top: 5px;
        margin-bottom: 5px;;
        padding-top: 4px;
        padding-bottom:4px;
        text-decoration: none;
        color: #005643;
      }
      &.planning {
        &.ceremony, &.drink, &.meal, &.party {
          flex-direction:column;
          padding-top: 40px;
        }
        &.ceremony {
         
          
            .description-planningText-0 {
              &:before {
                background-image: url('./assets/ceremony.svg');
                content: '';
                position:absolute;
                width: 40px;
                height: 40px;
                margin-top:-40px;
                background-size: contain;
                margin-left: 15px;
              }
            }
         }
        &.drink {
          .description-planningText-0 {
            &:before {
              background-image: url('./assets/drink.svg');
              content: '';
              position:absolute;
              width: 40px;
              height: 40px;
              margin-top:-40px;
              background-size: contain;
              margin-left: 20px;
            }
        }
        &.nomargin {
          .description-planningText-0 {
            &:before {
              margin-left:0;
            }
          }
        }
        }
        &.meal {
          padding-top: 40px;
            .description-planningText2-0 {
              &:before {
                background-image: url('./assets/meal.svg');
                content: '';
                position:absolute;
                width: 40px;
                height: 40px;
                margin-top:-40px;
                background-size: contain;
                margin-left: -3px;
              }
            }
            &.nomargin {
              .description-planningText2-0 {
                &:before {
                  margin-left:0;
                }
              }
            }
        }
        &.party {
          .description-planningText2-0 {
            &:before {
              background-image: url('./assets/party.svg');
              content: '';
              position:absolute;
              width: 40px;
              height: 40px;
              margin-top:-40px;
              background-size: contain;
            }
          }
        }
      }
  }
  @media only screen and (max-width: 400px) {
  .content-container:not(.last) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    >div:not(.separator) {
      width:60%;
      &.submit-container
      {
        //border: 1px solid black;
        width: 40%;
        .submit
        {
          display:inline-block;
          margin-top: 0;
          padding-top: 4px;
          padding-bottom:4px;
          text-decoration: none;
          color: #005643;
        }
      }
      &.address {
        text-align: left;
        padding-left: 40px;
      }
    }
  }
}
  h2 {
    text-decoration:none;
    font-style: italic;
  }

  
}

.pictures {
  align-items: center;
  display: flex;
  flex-direction: column;
  > img {
    margin-top:15px;
    max-width: 25%;
    max-height: 320px;
    @media only screen and (max-width: 400px) {
      max-width: 320px;
    }
  }
}

.footer {
  margin-top: auto;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top:10px;
  padding-bottom: 5px;
  padding-top: 5px;
  span {
    font-style: italic;
  }
  
}

.text-blur-out {
	-webkit-animation: text-blur-out 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-blur-out 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.text-blur-out2 {
animation-delay: 5s;
-webkit-animation: text-blur-out 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-blur-out 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@-webkit-keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}

.vibrate-3 {
	-webkit-animation: vibrate-3 0.5s linear 5 both;
	        animation: vibrate-3 0.5s linear 5 both;
}
@-webkit-keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}



.separator {
  min-height: 1px;
  margin: 20px 0px;
  background-color: #c2c2c2;
}

.partner.col {
  display: flex;
  flex-direction: column;  
  align-self: center;
  min-width: 320px;
  //width: 100%;
  &.map {
    :first-child {
      margin-bottom: 10px;
    }
  }
  /*.map
  {
    margin-right:20px;
    max-width: 50%;
    @media only screen and (max-width: 400px) {
      margin-top: 1rem;
    }
  }*/
  .content
  {
    
    margin-left:20px;
    @media only screen and (max-width: 400px) {
      margin: 0 auto;
    }
    text-align: center;
    @media (max-width: 576px) {
      max-width: 100%;
    }
    //border: 1px solid black;
    display: flex;
    flex-direction: column;
    &.left {
      align-items: start;
    }
    &.right {
      align-items: end;
    } 
  }
}

.names {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
}
.attendees {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  .children {
    margin-left: 2rem;
  }
}

.patrick
{
  display: none;
  height: 200px;
  &:before {
    content: "";
    background-color: white;
    background-image: url('./assets/patrick.png');
    background-repeat: no-repeat;
    background-position:left;
    position: fixed;
    background-size: inherit;
    right: 0px;
    bottom: 338px;
    left: 0px;
    z-index:-1;
    height: 200px;
    @media only screen and (max-width: 400px) {
      height: 200px;
    background-size: contain;
    }
  }
  &.active{
    display: block;
    -webkit-animation: slide-in-bottom 4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
}
.coucou {
  display: none;
  height: 67px;
  &:before {
    content: "";
    background-image: url('./assets/coucou.png');
    background-repeat: no-repeat;
    background-position:right;
    position: fixed;
    background-size: inherit;
    right: 5px;
    bottom: 160px;
    left: 0px;
    z-index:2;
    height: 67px;
    @media only screen and (max-width: 400px) {
      height: 67px;
    background-size: contain;
    }
  }
  &.active{
    display: block;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(600px);
            transform: translateY(600px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(160px);
            transform: translateY(160px);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(600px);
            transform: translateY(600px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(160px);
            transform: translateY(160px);
    opacity: 1;
  }
}

