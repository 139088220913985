  :root {
    --gray-900: #111114;//
    --gray-800: #222224;
    --gray-700: #444444;//
    --gray-600: #666664;
    --gray-500: #888884;
    --gray-400: #aaaaa4;
    --gray-300: #ccccc4;
    --gray-200: #e2e2e4;
    --gray-100: #f9f9f9;
    --white: white;
    --blue: #4299e1;
    --red: #f56565;
    --green: #005643;
    --text-decoration-color: var(--green);
    --text-color: var(--green);
    --focus-ring-color: var(--green);
    --border-radius: 4px;
    color-scheme: light dark;
  }
  
  ::selection,
  ::-moz-selection {
    background: var(--focus-ring-color);
  }

  h1 {
    font-size: 1.25em;;
  }

  @media (prefers-color-scheme: dark) {
    body {
      color: var(--green);// white;
      background-color: #e7e7e7;//var(--gray-900);
      caret-color: var(--green);// white;
    }
    h1, label {
      color: var(--green);
    }
  }
  /*
  a {
    color: inherit;
    text-decoration: underline;
    text-decoration-color: var(--text-decoration-color);
    -webkit-text-decoration-color: var(--text-decoration-color);
    border-radius: 5px;
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
  }
  
  @media (hover: hover) {
    a:hover {
      text-decoration-color: var(--gray-900);
    }
  }
  
  @media (hover: hover) and (prefers-color-scheme: dark) {
    a:hover {
      text-decoration-color: white;
    }
  }
  
  @media (prefers-color-scheme: dark) {
    a {
      text-decoration-color: var(--gray-600);
      -webkit-text-decoration-color: var(--gray-600);
    }
  }
  
  a:focus-visible {
    box-shadow: 0 0 0 2px var(--focus-ring-color);
    outline: none;
  }
  */
  small {
    font-size: 0.888rem;
  }
  
  hr {
    border: 1px solid var(--gray-700);
    margin: 3.052rem 0;
  }
  
  /* Form
  ––––––––––––––––––––––––––––––––– */
  label {
    font-weight: bold;
    display: flex;
    color: var(--green);
  }

  
  input[type='email'],
  input[type='text'],
  input[type='number'] {
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    background-color: var(--gray-100);
    color: var(--green);
    border-radius: var(--border-radius);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='email'],
    input[type='text'],
    input[type='number'] {
      background-color: #fff;
      border-color: var(--green);
      color: var(--green)
    }
  }
  
  input[type='email']:hover,
  input[type='text']:hover,
  input[type='number']:hover,
  textarea:hover {
    border: 2px solid var(--green);
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='email']:hover,
    input[type='text']:hover,
    input[type='number']:hover,
    textarea:hover {
      border: 2px solid var(--green);
    }
  }
  /*
  select {
    -webkit-appearance: none;
    padding: 0.65rem 0.5rem;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    border-radius: var(--border-radius);
    color: var(--gray-700);
    height: 2.5rem;
    background-color: var(--gray-100);
    background-image: url('data:image/svg+xml;utf8,<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
    background-repeat: no-repeat;
    background-size: 1rem;
    background-position: center right 0.5rem;
  }
  
  @media (prefers-color-scheme: dark) {
    select {
      background-color: var(--gray-700);
      background-image: url('data:image/svg+xml;utf8,<svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
      border-color: var(--gray-700);
      color: white;
    }
  }
  
  select[multiple] {
    height: auto;
    background-image: none;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: initial;
  }
  
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    border: 2px solid var(--gray-200);
    color: var(--gray-700);
    border-radius: var(--border-radius);
    resize: vertical;
    background-color: var(--gray-100);
    box-sizing: border-box;
    padding: 0.65rem 0.5rem;
    font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir,
      'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
      sans-serif;
  }
  
  @media (prefers-color-scheme: dark) {
    textarea {
      background-color: var(--gray-700);
      border-color: var(--gray-700);
      color: white;
    }
  }
  */
  input:focus,
  select:focus,
  textarea:focus,
  input:hover:focus,
  select:hover:focus,
  textarea:hover:focus {
    outline: none;
    border: 2px solid var(--focus-ring-color);
  }
  
  input:invalid,
  select:invalid,
  textarea:invalid {
    border: 2px solid #ff7d87;
    box-shadow: none;
  }
  
  input[type='checkbox'] {
    display: inline-block;
    height: 1rem;
    font-size: 1rem;
    border-radius: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 2px solid var(--gray-300);
    width: 1rem;
    background-color: white;
    align-self: center;
    margin-right: 0.5rem;
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='checkbox'] {
      background-color: #FFF;//var(--gray-900);
      border-color: none;
    }
  }
  
  input[type='checkbox']:hover {
    cursor: pointer;
    border: 2px solid var(--gray-300);
  }
  
  input[type='checkbox']:checked {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="white" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
    background-size: contain;
    background-color: var(--gray-700);
    border: 2px solid var(--gray-700);
  }
  
  input[type='checkbox']:focus-visible,
  input[type='checkbox']:checked:focus-visible {
    border-color: var(--focus-ring-color);
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='checkbox']:checked {
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="78.369" height="78.369" viewBox="0 0 78.369 78.369"><path fill="%23343434" d="M78.05 19.015l-48.592 48.59c-.428.43-1.12.43-1.548 0L.32 40.016c-.427-.426-.427-1.12 0-1.547l6.704-6.704c.428-.427 1.12-.427 1.548 0l20.113 20.112 41.113-41.113c.43-.427 1.12-.427 1.548 0l6.703 6.704c.427.427.427 1.12 0 1.548z"/></svg>');
      background-color: white;
      border: 2px solid white;
    }
  }
  /*
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    border: 2px solid var(--gray-300);
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    align-self: center;
    justify-content: center;
    position: relative;
    display: flex;
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='radio'] {
      border: 2px solid var(--gray-200);
    }
  }
  
  @media (hover: hover) {
    input[type='radio']:hover {
      cursor: pointer;
    }
  }
  
  input[type='radio']:checked {
    border: 2px solid var(--gray-700);
  }
  
  input[type='radio']:focus-visible,
  input[type='radio']:checked:focus-visible {
    border-color: var(--focus-ring-color);
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='radio']:checked {
      border: 2px solid white;
    }
  }
  
  input[type='radio']:checked::before {
    content: '';
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background-color: var(--gray-700);
    align-self: center;
    border-radius: 50%;
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='radio']:checked::before {
      background-color: white;
    }
  }
  */
  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button[type='submit'],
  button[type='reset'] {
    padding: 0.5rem 1.25rem;
    font-size: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--gray-700);
    border: 2px solid var(--gray-700);
    color: white;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    line-height: initial;
    transition: background-color 200ms ease-in-out, border 200ms ease-in-out,
      transform 200ms ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
  button:hover {
    cursor: pointer;
  }
  input[type='submit']:hover,
  input[type='button']:hover,
  button[type='submit']:hover {
    background: var(--gray-900);
  }
  
  @media (prefers-color-scheme: dark) {
    input[type='submit'],
    input[type='button'],
    button[type='submit'] {
      background-color: white;
      border: 2px solid var(--green);
      color: var(--green);
    }
  
    input[type='submit']:hover,
    input[type='button']:hover,
    button[type='submit']:hover {
      background-color: var(--gray-300);
      border: 2px solid var(--gray-300);
      cursor: pointer;
    }
  }
  
  button:focus-visible,
  input[type='submit']:focus-visible,
  input[type='reset']:focus-visible,
  input[type='button']:focus-visible {
    border-color: var(--focus-ring-color);
    outline: none;
  }
  
  /* Tables
  ––––––––––––––––––––––––––––––––– */
  table {
    width: 100%;
    border-spacing: 0;
    margin-bottom: 1.563rem;
    font-variant-numeric: tabular-nums;
  }
  
  th,
  td {
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin: 0;
  }
  
  th {
    font-weight: bold;
    text-align: left;
    border-bottom: 2px solid var(--gray-300);
    color: var(--gray-300);
  }
  
  @media (prefers-color-scheme: dark) {
    th {
      border-color: var(--gray-700);
      color: var(--gray-700);
    }
  }
  
  td {
    border-bottom: 2px solid var(--gray-300);
  }
  
  @media (prefers-color-scheme: dark) {
    td {
      border-color: var(--gray-700);
    }
  }
  
  /* Code
  ––––––––––––––––––––––––––––––––– */
  code {
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
    font-size: 0.8rem;
    white-space: nowrap;
    background: var(--gray-100);
    padding: 0 0.328rem;
    display: inline-block;
    vertical-align: middle;
    border-radius: var(--border-radius);
  }
  
  @media (prefers-color-scheme: dark) {
    code {
      background-color: var(--gray-700);
    }
  }
  
  p > code {
    white-space: normal;
  }
  
  pre > code {
    line-height: 1.563em;
    display: block;
    padding: 1rem;
    white-space: pre;
    margin-bottom: 1.563rem;
    overflow: scroll;
  }
  
  /* Forces a new-line at the end of a code block for layout purposes. */
  pre > code::after {
    content: ' ';
  }
  
  /* Blockquote
  ––––––––––––––––––––––––––––––––– */
  blockquote {
    border-left: 0.25rem solid var(--gray-100);
    padding: 0 1rem;
    margin-bottom: 1.563rem;
  }
  
  @media (prefers-color-scheme: dark) {
    blockquote {
      border-left: 0.25rem solid var(--gray-700);
    }
  }
  
  /* List
  ––––––––––––––––––––––––––––––––– */
  ul {
    margin: 0;
    padding: 0 1px;
    list-style: disc outside;
    font-variant-numeric: tabular-nums;
  }
  
  ol {
    list-style: decimal outside;
  }
  
  ol,
  ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 1.563rem;
  }
  
  li {
    list-style-position: inside;
  }
  
  /* Keyboard
  ––––––––––––––––––––––––––––––––– */
  kbd {
    display: inline-block;
    padding: 0 0.328rem;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
      monospace;
    font-size: 11px;
    color: var(--gray-700);
    vertical-align: middle;
    background-color: var(--gray-100);
    border: solid 1px var(--gray-300);
    border-bottom: solid 2px var(--gray-500);
    border-radius: 5px;
  }
  
  /* Abbreviation
  ––––––––––––––––––––––––––––––––– */
  abbr {
    text-decoration: none;
    border-bottom: 2px dashed var(--gray-700);
  }
  
  @media (hover: hover) {
    abbr:hover {
      cursor: help;
    }
  }
  
  .input-feedback {
    color: red;
    margin-top: 0.25rem;
  }
  
  .error {
    color: red;
  }
  
  .success {
    color: green;
  }

  .form {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  
  .form-control {
    margin-top: 1rem;
  }
  /*
  .valid input[type='email'],
  .valid input[type='text'],
  .valid input[type='number'] {
    border-color: green;
  }
  
  .valid .feedback {
    color: green;
  }
  */
  
  .invalid input[type='email'],
  .invalid input[type='text'],
  .invalid input[type='number'] {
    border-color: red;
  }
  
  .invalid .feedback {
    color: red;
  }

  @media (prefers-color-scheme: dark) {
    .invalid input[type='email'],
    .invalid input[type='text'],
    .invalid input[type='number'] {
      border-color: red;
    }
    
    .invalid .feedback {
      color: red;
    }
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  
  button + button {
    margin-left: 0.5rem;
  }
  
  button.outline,
  button[type='reset'],
  input[type='reset'] {
    background-color: var(--gray-200);
    border: 2px solid var(--gray-200);
    color: var(--gray-900);
  }
  button.outline:hover,
  button[type='reset']:hover,
  input[type='reset']:hover {
    background-color: var(--gray-300);
    border: 2px solid var(--gray-300);
  }
  
  @media (prefers-color-scheme: dark) {
    button.outline,
    button[type='reset'],
    input[type='reset'] {
      background-color: var(--gray-800);
      border: 2px solid var(--gray-700);
      color: var(--gray-100);
    }
  
    button.outline:hover,
    button[type='reset']:hover,
    input[type='reset']:hover {
      background-color: var(--gray-900);
      border: 2px solid var(--gray-700);
      color: var(--gray-200);
      cursor: pointer;
    }
  }
  
  input[type='email'],
  input[type='text'],
  input[type='number'],
  textarea,
  select {
    width: calc(100% - 20px);
  }
  
  label.checkbox {
    font-weight: normal;
  }
  
  button[type='submit'],
  button[type='reset'] {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  
  .app {
    margin: 0 auto;
    max-width: 500px;
    padding: 1rem;
  }
  
  .example {
    padding: 1rem;
    border: 1px solid var(--gray-200);
    border-radius: var(--border-radius);
  }
  
  .example label:first-child,
  .example .form-control:first-child {
    margin-top: 0;
  }
  
  @media (prefers-color-scheme: dark) {
    .example {
      border: 1px solid var(--gray-700);
    }
  }
  
  .text-xs {
    font-size: 0.75rem;
  }
  .text-sm {
    font-size: 0.875rem;
  }
  .text-base {
    font-size: 1rem;
  }
  .text-lg {
    font-size: 1.125rem;
  }
  .text-xl {
    font-size: 1.25rem;
  }
  .text-2xl {
    font-size: 1.5rem;
  }
  .text-3xl {
    font-size: 1.875rem;
  }
  .text-4xl {
    font-size: 2.25rem;
  }
  .text-5xl {
    font-size: 3rem;
  }
  .text-6xl {
    font-size: 4rem;
  }
  
  .flex {
    display: flex;
  }
  
  .space-between {
    justify-content: space-between;
  }
  
  .items-center {
    align-items: center;
  }
  
  .mt-1 {
    margin-top: 1rem;
  }
  

  /* Animation */
  .form-control {
    &.invalid {
      .bounce {
        outline: 0;
        border-color: red;
        animation-name: bounce;
        animation-duration: .5s;
        animation-delay: 0.25s;
      }
    }
  }

  .rsvp {
    border : 2px solid #005643;
    border-radius: 5px;
    width : 80px;
    margin: 0 auto;
    cursor: pointer;
  }
  
  
  /* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
  @keyframes bounce {
    0% {
      transform: translateX(0px);
      timing-function: ease-in;
    }
    37% {
      transform: translateX(5px);
      timing-function: ease-out;
    }
    55% {
      transform: translateX(-5px);
      timing-function: ease-in;
    }
    73% {
      transform: translateX(4px);
      timing-function: ease-out;
    }
    82% {
      transform: translateX(-4px);
      timing-function: ease-in;
    }
    91% {
      transform: translateX(2px);
      timing-function: ease-out;
    }
    96% {
      transform: translateX(-2px);
      timing-function: ease-in;
    }
    100% {
      transform: translateX(0px);
      timing-function: ease-in;
    }
  }

  
  .submit/*, .submit2 */{
    margin-top: 2rem;
    border: 1px solid #005643;
    align-self: center;
    padding: 8px 10px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: #daf7f0;// #ccc;//#dfdbdb;//#87c2f3;
      font-weight:600;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      margin-left: 8px;
    }
  }
 /* .submit2 {
    margin-top:0;
    margin-bottom: 1rem;;
    &:hover {
      background-color: #2196F3;
      font-weight:600;
    }
  }*/

  .response {
    font-size: 1.5rem;
    margin: 3rem auto;
    text-align: center;
    color:#005643;
  }

// Main wrap
.buttons {
    display: flex;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    text-align: center;
    width: 100%;
    align-items: baseline;
    margin-top: 1rem;
}


  .btn {
    color: #005643;
    cursor: pointer;
    font-size:16px;
    font-weight: 400;
    line-height: 45px;
    margin: 0 0 2em;
    max-width: 160px; 
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%; 
   
    @media(min-width: 600px) {
      margin: 0 1em 2em;
    }
    &:hover { text-decoration: none; }
  }
  
  /*
  .btn-2 {
      letter-spacing: 0;
  }
  
  .btn-2:hover,
  .btn-2:active {
    letter-spacing: 5px;
  }
  
  .btn-2:after,
  .btn-2:before {
    backface-visibility: hidden;
    border: 1px solid rgba(#000, 0);
    bottom: 0px;
    content: " ";
    display: block;
    margin: 0 auto;
    position: relative;
    transition: all 280ms ease-in-out;
    width: 0;
  }
  
  .btn-2:hover:after,
  .btn-2:hover:before {
    backface-visibility: hidden;
    border-color: #000;
    transition: width 350ms ease-in-out;
    width: 70%;
  }
  
  .btn-2:hover:before {
    bottom: auto;
    top: 0;
    width: 70%;
  }

  .btn3, .btn4 {
    margin: 1rem 1rem 1rem 0;
    transition: all .5s ease;
    color: #000;
    border: 3px solid black;
    font-family:'Montserrat', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    font-size: 17px;
    background-color : transparent;
    padding: 10px;
    outline: none;
    border-radius: 4px;
    text-decoration: none;
}
.btn3:hover {
    color: #c2c2c2;
    background-color: #000;
    cursor:pointer;

}

.btn4
{
border-color: #2196F3;
&:hover {
  color: #FFF;
  background-color: #2196F3;
  cursor:pointer;
}
}
*/

@media (prefers-color-scheme: dark) {
  label, h1/*,.btn,.btn3, .btn4 */{
    color: var(--green);//#FFF;
    opacity: 0.87;
  }
 /* .btn-2:after,
  .btn-2:before {
    border-color: rgba(#FFF, 0);
  }
  
  .btn-2:hover:after,
  .btn-2:hover:before {
    border-color: #FFF;
  }
  .btn3 {
    border-color: #FFF;
  }
  .btn3:hover {
    color: #FFF;
    background-color: #ccc;
  }*/
  .submit/*, .submit2 */{
    border-color: var(--green);//#FFF;
    &:hover {
      color: var(--green);//#111114;
    }
  }
  .rsvp {
    border : 2px solid var(--green);//#FFF;
  }
  
}